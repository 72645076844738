.portfolio{
    width: 100vw;
    height: 100vh;
    background-image: url(../../images/backgroundPort.webp);
    background-size: cover;
    display: flex;
    position: absolute;
}

.main{
    width: 88%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 500px) {
    .portfolio{
        background-position: 75%, 30%;
    }
}