.newmain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 70%;
}

.newmain h1{
    position: relative;
    width: 50%;
    left: 10%;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 0px;
    font-size: 4vw;
    letter-spacing: 10px;
}

.newmain p{
    position: relative;
    width: 50%;
    left: 12%;
    color: white;
    text-transform: uppercase;
    font-weight: 100;
    line-height: 0px;
    font-size: 2vw;
    letter-spacing: 25px;
    margin-top: 10px;
}

.i{
    color: #ed7700;
}

.buttons{
    display: flex;
    position: relative;
    left: 10%;
    width: 35%;
    justify-content: space-around;
    padding: 2%;
}

.resume-btn, .portfolio-btn{
    width: 200px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 30px;
    background-color: rgb(0, 0, 0, 0.5);
    color: #fff;
    font-weight: 100;
    font-size: 1.2vw;
    letter-spacing: 7px;
}

/*-------------------------------- BUTTONS -----------------------------------*/


.animated-button {
    position: relative;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px 36px;
    border: 4px solid;
    border-color: transparent;
    font-size: 16px;
    background-color: rgb(0, 0, 0, 0.3);
    border-radius: 100px;
    font-weight: 600;
    color: #fff;
    box-shadow: 0 0 0 2px #ed7700;;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    letter-spacing: 5px;
  }
  
  .animated-button svg {
    position: absolute;
    width: 24px;
    fill: #ed7700;;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .arr-1 {
    right: 16px;
  }
  
  .animated-button .arr-2 {
    left: -25%;
  }
  
  .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #ed7700;;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: #212121;
    border-radius: 50px;
  }
  
  .animated-button:hover .arr-1 {
    right: -25%;
  }
  
  .animated-button:hover .arr-2 {
    left: 16px;
  }
  
  .animated-button:hover .text {
    transform: translateX(12px);
  }
  
  .animated-button:hover svg {
    fill: #212121;
  }
  
  .animated-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px #ed7700;;
  }
  
  .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  

/*#arrowAni{
    position: absolute;
    width: 500px;
    transform: rotate(130deg);
    left: 500px;
    top: 300px;
}


#dude{
    font-size: 1vw;
    position: absolute;
    top: 130px;
    left: 50%;
}

#here{
    letter-spacing: 3px;
    font-size: 1vw;
    position: absolute;
    top: 75%;
    left: 29%;
} 

.hide {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .show {
    opacity: 1;
    transition: opacity 0.5s ease;
  } */

  @media only screen and (max-width: 500px) {
   
    .newmain h1{
        position: relative;
        width: 90%;
        left: 7%;
        font-size: 6vw;
        top: -20%;
    }

    .newmain p{
        position: relative;
        width: 90%;
        left: 7.5%;
        font-weight: 100;
        line-height: 0px;
        font-size: 2vw;
        letter-spacing: 20px;
        top: -20%;
    }

    .buttons{
        display: flex;
        position: relative;
        width: 90%;
        left: 0%;
        justify-content: space-around;
        padding: 2%;
        top: -15%;
    }
    
    .resume-btn, .portfolio-btn{
        width: 150px;
        height: 40px;
        border: 1px solid #fff;
        border-radius: 30px;
        background-color: rgb(0, 0, 0, 0.5);
        color: #fff;
        font-weight: 100;
        font-size: 2.5vw;
        letter-spacing: 7px;
    }

    .animated-button{
        width: 150px;
    }
}





@media only screen and (max-width: 768px) {

  .buttons{
    display: flex;
    flex-direction: column;
    position: relative;
    left: 10%;
    width: 50%;
    justify-content: space-around;
    padding: 2%;
    gap: 25px;
    top: 0px;
}

.buttons button{
  width: 200px;
}

}