@import url('https://fonts.googleapis.com/css2?family=Jersey+10&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Anpassar bredden på scrollbaren */
::-webkit-scrollbar {
  width: 3px; /* eller höjd om det är en horisontell scrollbar */
}

/* Anpassar färgen och andra egenskaper på spåret (delen scrollbar rör sig längs) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px; /* rundar av hörnen på spåret */
}

/* Anpassar själva rörliga delen (thumb) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px; /* rundar av hörnen på thumb */
  border: 3px solid transparent; /* lägger till en border runt thumb */
}

/* Ändrar färg på thumb vid hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
