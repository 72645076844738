.newHome{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.5)), url(../../images/background-dark.webp);
    background-size: cover;
    overflow-y: hidden;
}


@media only screen and (max-width: 500px) {
    .newHome{
        background-position: 90%, 75%;
    }
}