a{
    color: #fff;
    text-decoration: none;
}

.navbar{
    width: 100%;
    height: 10%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.navbar ul{
    width: 40%;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    letter-spacing: 5px;
    font-size: 1.2vw;
}


.navbar p{
    color: #fff;
    position: relative;
    right: 2%;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 1vw;
}

.tele-icon{
    width: 20px;
}


@media only screen and (max-width: 500px) {
    .navbar ul{
        font-size: 2vw;
        margin-left: 5%;
        gap: 20px;
    }

    .navbar p{
        visibility: hidden;
    }
}
