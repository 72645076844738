.resume{
    width: 100%;
    height: 300vh;
    background-image: linear-gradient(rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.5)), url(../../images/background-dark.webp);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.resumeDiv{
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 160px;
}

.resumeBox{
    width: 40%;
    height: auto;
    border-top-right-radius: 30px;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
}

.resumeBox h1{
    text-transform: uppercase;
    font-weight: 100;
}

.signDiv{
    position: relative;
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.sign{
    position: relative;
    width: 300px;
    height: 300px;
}

.fotoDivs{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.fotoDiv{
    width: 800px;
    height: 400px;
    background-color: chocolate;
    border-radius: 10px;
    border-bottom: 2px solid #603209;
    border-right: 2px solid #603209;
    transition: background 0.5s ease; /* Transition-effekt för bakgrunden */
}

#bild1 {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url(../../images/vegaochjagbild.png);
    background-size: cover;
    background-position: 50% 30%;
}

#bild1:hover {
    background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.1)), url(../../images/vegaochjagbild.png);
    background-size: cover;
    background-position: 50% 30%;
}

#bild2 {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url(../../images/bild1.png);
    background-size: cover;
    background-position: 50% 80%;
}

#bild2:hover {
    background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.1)), url(../../images/bild1.png);
    background-size: cover;
    background-position: 50% 80%;
}

#bild3 {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url(../../images/IMG_1616.jpg);
    background-size: cover;
    background-position: 50% 50%;
}

#bild3:hover {
    background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.1)), url(../../images/IMG_1616.jpg);
    background-size: cover;
    background-position: 50% 50%;
}


.resumeBox::-webkit-scrollbar {
    display: none; /* Döljer scrollbar för Webkit-baserade webbläsare (Chrome, Safari) */
}

.inner-content {
    height: 100%;
    padding-right: 15px; /* För att förhindra att innehållet klipps av */
    box-sizing: content-box; /* Säkerställer att padding inte påverkar scrollningen */
}

/* För vanliga scrollbar (standard) */
::-webkit-scrollbar {
    width: 2px; /* Ändra bredden */
}

/* För scrollbarens "track" (bakgrund) */
::-webkit-scrollbar-track {
    background: #f1f1f1; /* Bakgrundsfärg */
}

/* För själva scrollbaren */
::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar-färg */
    border-radius: 10px; /* Rundar hörnen på scrollbaren */
}


@media only screen and (max-width: 550px) {

    .resume{
        width: 100%;
        height: auto;
        background-image: linear-gradient(rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.5)), url(../../images/background-dark.webp);
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .resumeDiv{
        width: 90%;
        height: 90%;
        background-color: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-wrap: nowrap;
    }

    .resumeBox{
        height: auto;
        width: 90%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        overflow: hidden;
    }

    .fotoDivs{
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    
    .fotoDiv{
        width: 300px;
        height: 300px;
        background-color: chocolate;
        border-radius: 10px;
        background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)) url(../../images/bild1.png);
        background-size: cover;
        background-position: 50% 80%;
    }
    

}




.bottom{
    width: 100%;
    height: 20%;
}