
.contacts{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
}

.contact-info{
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    letter-spacing: 3px;
    line-height: 5px;
    color: #ffffff;
}


.head{
    font-size: 1.5vw;
}



.email, .phone{
    padding-right: 30px;
    border-right: 1px solid #ed7700;
}


@media only screen and (max-width: 500px) {

    .contact-info{
        width: 80%;
        position: relative;
        top: -40%;
        display: flex;
        flex-direction: column;
        left: 5%;
    }

    .email, .phone, .location{
        padding-left: 20px;
        border-left: 5px solid #ed7700;
        border-right: 0px solid #ed7700;
    }

    .head{
        font-size: 3vw;
    }
 
}