.contact{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.5)), url(../../images/background-dark.webp);
    background-size: cover;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.contactDiv{
    width: 80%;
    height: 70%;
    top: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;    
    gap: 20px;
    background-color: rgb(0, 0, 0, 0.8);
    align-self: center;
}

.letsTalk{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 100;
    justify-content: space-evenly;
}

.headline-talk{
    font-size: 40px;
    color: antiquewhite;
    position: relative;
    left: 10%;
    font-weight: 100;
}

.under-talk{
    font-size: 15px;
    color: antiquewhite;
    position: relative;
    left: 10%;
}

.linksToMe{
    width: auto;
    height: auto;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    left: 10%;
}

.contactLinks{
    color: aliceblue;
    flex-wrap: wrap;
    font-size: 1vw;
    font-weight: 100;
    text-transform:uppercase;
    position: relative;
}

.form-holder{
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-holder form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#name, #email{
    width: 200px;
    height: 30px;
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    margin-right: 10px;
    color: #fff;
}

#message{
    width: 410px;
    height: 100px;
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
}

.btn-send{
    width: 100px;
    height: 30px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    margin-top: 50px;
    border-radius: 10px;
}


.status-message{
    color: #fff;
    font-size: 15px;
    font-weight: 100;
}


@media only screen and (max-width: 550px) {


    .contact{
        width: 100%;
        height: auto;
        background-image: linear-gradient(rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.5)), url(../../images/background-dark.webp);
        background-size: cover;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
    }


    .contactDiv{
        width: 90%;
        height: 100%;
        top: 15%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;    
        gap: 20px;
        background-color: rgb(0, 0, 0, 0.8);
        align-self: center;
        flex-wrap: wrap;
    }

    .letsTalk{
        width: 100%;
        height: 40vh;
        display: flex;
        flex-direction: column;
        font-weight: 100;
        margin-left: 10%;
        margin-top: 10%;
        flex-wrap: wrap;
    }

    .form-holder{
        margin-bottom: 20%;
        width: 100%;
        height: 60vh;
    }

    .headline-talk{
        font-size: 40px;
        color: antiquewhite;
        position: relative;
        left: 0%;
        top: 0%;
        font-weight: 100;
    }
    
    .under-talk{
        font-size: 15px;
        color: antiquewhite;
        position: relative;
        left: 0%;
        top: 0%;
    }


    .linksToMe{
        width: auto;
        height: auto;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-end;
        position: relative;
        left: 0%;
    }

    .inputDiv{
        width: 300px;
    }


    #name, #email{
        width: 300px;
        height: 30px;
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        color: #fff;
        margin-right: 0;
    }

    #message{
        width: 300px;
        height: 100px;
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        color: #fff;
    }

    .btn-send{
        width: 100px;
        height: 30px;
        border: 1px solid #fff;
        background: none;
        color: #fff;
        margin-top: 50px;
    }


    .status-message{
        color: #fff;
        font-size: 15px;
        font-weight: 100;
    }
    
}